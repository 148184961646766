import { ReactElement, useCallback, useMemo, useState } from "react"
import { Element as ScrollableAnchor } from "react-scroll"
import dynamic from "next/dynamic"
import { useI18n } from "next-localization"
import cn from "clsx"

import { SIZE } from "@app/constants"
import { useGlobalState } from "@app/contexts"
import { HidingScene } from "@app/features/hiding-media-files/components"
import { ActionBar, ActionBarVideo } from "@app/features/masonry/components"
import { MasonryHorizontalProps } from "@app/features/masonry/components/horizontal/masonry-horizontal"
// NOTE: Динамические импорты не принимают generics.
import { MasonryProps } from "@app/features/masonry/components/masonry/masonry"
import { ScenePhotoPreview } from "@app/features/masonry/components/scene-photo-preview"
import { VideoPreview } from "@app/features/masonry/components/video-card/video-preview"
import { MediaFile, MediaFileType } from "@app/features/masonry/types"
import { useGetAllPhotos, usePhotoCarousel } from "@app/features/photo-carousel/store/hooks"
import { Container } from "@app/ui/container"
import { isMobileDevice } from "@app/utils"

import { WatermarkLoader } from "../watermark-loader"

const Masonry = dynamic(() => import("@app/features/masonry/components/masonry/masonry").then((mod) => mod.Masonry), {
  ssr: false,
}) as <T extends MediaFile>(props: MasonryProps<T>) => ReactElement

const MasonryHorizontal = dynamic(
  () => import("@app/features/masonry/components/horizontal/masonry-horizontal").then((mod) => mod.MasonryHorizontal),
  {
    ssr: false,
  }
) as <T extends MediaFile>(props: MasonryHorizontalProps<T>) => ReactElement

import styles from "./scene.module.scss"

const ELEMENT_CLASS_NAMES: Record<MediaFileType, string> = {
  photo: "photo.photo",
  video: "",
}

const ELEMENT_ID_BUILDER: Record<MediaFileType, (itemId: string | number) => string> = {
  photo: (itemId) => `photo-${itemId}`,
  video: (itemId) => `video-${itemId}`,
}

function Scene({
  name,
  id,
  description,
  mediaFiles,
  settings,
  layout,
  isWithoutScenes,
  isDownloadEnabled,
  isPhotosInProgress,
  isCanBeHidden,
  isAllMediaFilesHidden,
  className,
  isHiddenScene,
}) {
  const isMediaFilesExist = mediaFiles.length > 0
  const isDescriptionVisible = settings.isDescriptionVisible
  const idScene = !isHiddenScene ? id : `hidden-${id}`
  const maxRowHeight = isMobileDevice() ? 300 : 650

  const {
    imageProxyHost,
    imgFormat,
    userAgent: { isMobile },
  } = useGlobalState()

  const i18n = useI18n()

  const [isHover, setIsHover] = useState(false)
  const isShowHidingBtn = isMobile || isHover || isHiddenScene
  function handleMouseEnter() {
    setIsHover(true)
  }
  function handleMouseLeave() {
    setIsHover(false)
  }

  // NOTE: Для соблюдения SRP нужно прокидывать хешированную функцию-маппер в ItemAsImage в компоонент HorizontalMasonry
  const mapItemToImage = useCallback(
    (file: MediaFile) => ({
      alt: "",
      width: file.width,
      height: file.height,
      src: file.type === "photo" ? file.photos[0] : file.fileKey,
      sourceItem: { ...file },
    }),
    []
  )

  const { $showPhotoCarousel, $setActivePhoto } = usePhotoCarousel()
  const $allMediaFiles = useGetAllPhotos()

  const handleMediaFilePreviewClick = useCallback(
    (mediaFile: MediaFile) => {
      const mediaFileIdex = $allMediaFiles.findIndex((item) => item.id === mediaFile.id)
      $setActivePhoto(mediaFileIdex)
      $showPhotoCarousel()
    },
    [$allMediaFiles, $setActivePhoto, $showPhotoCarousel]
  )

  const renderOverlay = useCallback(
    ({ isHovered, photo, srcImage }) => (
      // @ts-expect-error NOTE: Компонент на js
      <ActionBar
        photoId={photo.id}
        srcImage={srcImage}
        visible={isHovered}
        sizes={SIZE.original}
        isCanBeHidden={isCanBeHidden}
        isDownloadEnabled={isDownloadEnabled}
      />
    ),
    [isCanBeHidden, isDownloadEnabled]
  )

  const mediaItems = useMemo(() => mediaFiles || [], [mediaFiles])
  const getItemId = useCallback((item: MediaFile) => ELEMENT_ID_BUILDER[item.type](item.id), [])
  const getClassName = useCallback((item: MediaFile) => ELEMENT_CLASS_NAMES[item.type], [])
  const renderItem = useCallback(
    (mediaFile: MediaFile) => {
      return (
        <>
          {mediaFile.type === "video" && (
            <VideoPreview
              video={mediaFile}
              onClick={handleMediaFilePreviewClick}
              renderOverlay={({ isHovered, video, src }) => (
                // @ts-expect-error NOTE: Компонент на js
                <ActionBarVideo
                  src={src}
                  fileKey={mediaFile.fileKey}
                  visible={isHovered}
                  id={video.id}
                  name={video.name}
                  isCanBeHidden={isCanBeHidden}
                  isDownloadEnabled={isDownloadEnabled}
                />
              )}
            />
          )}
          {mediaFile.type === "photo" && (
            <ScenePhotoPreview
              isCursorPointer
              imageProxyHost={imageProxyHost}
              imgFormat={imgFormat}
              photo={mediaFile}
              onClick={handleMediaFilePreviewClick}
              renderOverlay={renderOverlay}
            />
          )}
        </>
      )
    },

    [handleMediaFilePreviewClick, isCanBeHidden, isDownloadEnabled, renderOverlay, imageProxyHost, imgFormat]
  )

  return (
    <ScrollableAnchor name={idScene} className={className}>
      <section
        className={cn(styles["scene"], {
          [styles["without-title"]]: isWithoutScenes,
        })}
        id={id}
      >
        <Container className={styles["container"]}>
          {!isWithoutScenes && name && (
            <h1
              className={cn(styles["title"], {
                [styles["with-photo"]]: isMediaFilesExist,
                [styles["without-photo"]]: !isMediaFilesExist,
                [styles["with-description"]]: isDescriptionVisible,
                [styles["with-hiding"]]: isCanBeHidden,
                [styles["is-hover-and-can-hidden"]]: isShowHidingBtn,
              })}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span className={styles["title-text"]}>{name}</span>
              {isCanBeHidden && (
                <HidingScene
                  id={id}
                  mediaFiles={mediaFiles}
                  isMediaFilesExist={isMediaFilesExist}
                  isHiddenScene={isHiddenScene}
                  isAllMediaFilesHidden={isAllMediaFilesHidden}
                />
              )}
            </h1>
          )}
          {isDescriptionVisible && description && (
            <p
              className={cn(styles["description"], {
                [styles["with-photo"]]: isMediaFilesExist,
                [styles["without-photo"]]: !isMediaFilesExist,
              })}
            >
              {description}
            </p>
          )}
          {!isMediaFilesExist && !isPhotosInProgress && (
            <p
              className={cn(styles["no-photos-message"], {
                [styles["with-description"]]: isDescriptionVisible,
                [styles["without-description"]]: !isDescriptionVisible,
              })}
            >
              {i18n.t("galleryPage.noPhotos")}
            </p>
          )}

          {layout === "vertical" && mediaFiles.length && (
            <Masonry<MediaFile>
              items={mediaItems}
              getItemId={getItemId}
              getItemClassName={getClassName}
              renderItem={renderItem}
            />
          )}

          {layout === "horizontal" && mediaFiles.length && (
            <MasonryHorizontal<MediaFile>
              maxRowHeight={maxRowHeight}
              mapItemToImage={mapItemToImage}
              items={mediaItems}
              getItemId={getItemId}
              getItemClassName={getClassName}
              renderItem={renderItem}
            />
          )}
          {isMediaFilesExist && isPhotosInProgress && (
            <WatermarkLoader text={i18n.t("galleryPage.watermark_in_progress")} />
          )}
        </Container>
      </section>
    </ScrollableAnchor>
  )
}

export { Scene }
